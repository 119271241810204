import React from "react";
import "../../components/Servicepage/Servicespage.css";

import { useState } from "react";

const ServiceCard = (props) => {
  const [cardClass, setCardClass] = useState(false);
  return (
    <div className="box">
      <div
        onMouseEnter={() => setCardClass(true) || console.log(cardClass)}
        onMouseLeave={() => setCardClass(false)}
        className="card"
      >
        <div className={`${cardClass ? "dynamicLogoBox" : ""} logoBox`}>
          <div className="logoContainer">
            <img className="appLogo" src={props.logo} alt="" />
          </div>
        </div>
        <h2 className="contentHeading">{props.title}</h2>
        <p className="contentText">{props.info}</p>
      </div>
    </div>
  );
};
export default ServiceCard;
