import React, { useEffect } from "react";
import "../PrivacyPolicypage/PrivacyPolicy.css";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="PrivacyPolicyHeading">
        <p className="PrivacyPolicyHeading2">Privacy Statement&nbsp;</p>
      </div>
      <div className="privacyPolicyContainer paragraphCenter">
        <div className="aboutTextBox">
            <br/>
            <h3>What Information do we collect ?</h3>
            <br/>
            <p>We collect information like Name, Email Address, Contact Number, that you voluntarily provide to us when you contact us for the Services, express an interest in obtaining information about us or our products and services. </p>
            <br/>
            <p>
            <b>Sensitive Information:</b> We do not process sensitive information.
            </p>
            <br/>
            <h3>How do we process your information?</h3>
            <p>
                We process your information to respond back to the questions that you ask. We are not storing any of the customers information.
            </p>
            <br/>
            <h3>Do we use cookies and other tracking technologies?</h3>
            <p>
                We are not using any cookies or any other tracking services to collect or store of your information.
            </p>
            <br/>
          <h3>Do we make update to this Notice ?</h3>
          <p>
            Yes, we will update this notice as necessary to stay compliant with relevant laws.
          </p>
          <br/>
          <h3>Handling Customer Complaints and Suggestions </h3>
          <p>
            You may direct any questions or enquiries with respect to our privacy policy or our practices by emailing us at <b>info@zyxan.tech</b>
          </p>
          
        </div>

      </div>
    </>
  );
};

export default PrivacyPolicy;
