import webdeveloplogo from "../../common/Assets/ServicePageImages/app-development.png";
import webapplicationlogo from "../../common/Assets/ServicePageImages/application.png";
import uianduxlogo from "../../common/Assets/ServicePageImages/ui-design.png";
import iotlogo from "../../common/Assets/ServicePageImages/internet-of-things.png";
import mobileapplogo from "../../common/Assets/ServicePageImages/app-settings.png";
import opensorcelogo from "../../common/Assets/ServicePageImages/browser (1).png";

const ServicesInfo = [
  {
    id: 1,
    logo: webdeveloplogo,
    title: `Web Design`,
    info: `With Zyxan vast knowledge, we help you to build the best user friendly designs. We love to solve problems and provide best solutions. We specialize in MVP (minimum viable product) design. A process for creating functional, production-ready products that serve user needs. Build, test, and iterate the results of an MVP with users. Let’s build together.`,
  },
  {
    id: 2,
    logo: webapplicationlogo,
    title: `Web Application`,
    info: `Never worry about your old website, We help in App modernization and migration of legacy systems to modern web apps. Build modern single page web applications using the reusable, dynamic, component driven architecture of React.js, Angular.js, Vue.js and more. `,
  },
  {
    id: 3,
    logo: uianduxlogo,
    title: `UI UX Design`,
    info: `There is no such thing as crazy idea when it comes to our UI UX design team. Our team helps you with best design by balancing usability and eye-catching graphics. User experience helps to understand user pain to solve and offer a user-friendly way to reach the goal. User Interface add the beauty to the goal reaching process.`,
  },
  {
    id: 4,
    logo: iotlogo,
    title: `IOT`,
    info: `Our technology is used by various industries and domains to get services like Medical treatment, Remote Control, Enhance Light Experience, the Automobile industry, Integration of AI applications, to get a better personalized experience, etc. Identifying pain points and specific needs of a unit or product line and defining prospective solutions.`,
  },
  {
    id: 5,
    logo: mobileapplogo,
    title: `Mobile Application`,
    info: `We create a fast and smooth app for any platform, be it iOS or Android. We also deliver products with an outsourcing app development model. Your idea, our design and development. We committed to make it affordable to all, so we are pocket friendly!.`,
  },
  {
    id: 6,
    logo: opensorcelogo,
    title: `Open Source`,
    info: `Zyxan contributes to open source technologies and projects. We have been contributing to various open-source projects. The reason why we contribute to open source is for the feeling of joy. It feels great when millions use your few lines of code in their day to day life.`,
  },
];

export default ServicesInfo;
