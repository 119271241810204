import { createSlice } from '@reduxjs/toolkit';

const intialState = {
  count: 0,
};

const counterSlice = createSlice({
  name: 'counter',
  initialState: intialState,
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    },
    reset: (state) => {
      state.count = 0;
    },
    incrementByAmount: (state, action) => {
      state.count += action.payload;
    },
  },
  extraReducers: {
    // add your async reducers here
  },
});

export const { increment, decrement, reset, incrementByAmount } =
  counterSlice.actions;

export const countSelector = (state) => state.counter.count;

export default counterSlice.reducer;
