import { API_URL } from '../config/';
import axios from 'axios';

class HttpClient {
  instance;
  constructor() {
    this.instance = axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.getToken(),
      },
    });

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // TODO: Add proper error handling
        return Promise.reject(error);
      },
    );
  }

  getToken() {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      return `Bearer ${JSON.parse(jwt)}`;
    }
    return '';
  }

  get = (url) => {
    return this.instance.get(url);
  };

  post = (url, data) => {
    return this.instance.post(url, data);
  };

  filePost = (url, data, headers) => {
    return this.instance.post(url, data, headers);
  };

  put = (url, data) => {
    return this.instance.put(url, data);
  };

  delete = (url) => {
    return this.instance.delete(url);
  };

  patch = (url, data) => {
    return this.instance.patch(url, data);
  };
}

export { HttpClient };
