import "./NavBar.css";
import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from "./DrawerComponent";
import mylogo from "./Logo/zyxan_logo.png";

function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <AppBar position="fixed">
      <CssBaseline />
      <Toolbar className="navbar">
        <Typography variant="h4" className="navbarBrand">
          <img className="navbarBrandLogo " src={mylogo} alt="" />
        </Typography>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className="navbarItemsDesktop">
            <Link to="/home" className="navbarItemDesktop">
              Home
            </Link>
            <Link to="/about" className="navbarItemDesktop">
              About
            </Link>
            <Link to="/services" className="navbarItemDesktop">
              Services
            </Link>
            <Link to="/products" className="navbarItemDesktop">
              Products
            </Link>
            <Link to="/clients" className="navbarItemDesktop">
              Clients
            </Link>
            <Link to="/career" className="navbarItemDesktop">
              Careers
            </Link>
            <Link to="/contact" className="navbarItemDesktop">
              Contact US
            </Link>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;

