const { REACT_APP_API_DEV_URL, REACT_APP_API_PROD_URL } = process.env;

// Get the API URL from the environment variables
let API_URL;
if (process.env.REACT_APP_ENV === 'production') {
  API_URL = REACT_APP_API_PROD_URL;
} else {
  API_URL = REACT_APP_API_DEV_URL;
}

export { API_URL };
