import iot from "../../common/Assets/ProductsPageImages/iOT.png";
import sql from "../../common/Assets/ProductsPageImages/myseql-1.png";
import world from "../../common/Assets/ProductsPageImages/74772631 1.png";
import json from "../../common/Assets/ProductsPageImages/myseql.png";

const ProductsData = [
  {
    id: 1,
    title: `IoT Hub`,
    Info: `The project aims to establish a robust communication platform between IoT devices and a cloud infrastructure for data collection, analysis, and control.It is an industrial IoT solution combination of Industry certificated gateway and software, iotHub allows us to connect with industrial devices like PLCs, VFD, SCADA, HMI. Etc. with industry standard IOT protocols – MQTT, CoAP and HTTP and supports both cloud and on-premises deployments.
    This allows you to create rich IoT Dashboards for data visualization and remote device control in real-time. customizable widgets allow you to build end-user custom dashboards for most IoT use-cases. User can create complex Rule Chains to process data from your devices and match your application specific use cases. iotHub Platform is scalable, fault-tolerant, robust, efficient, customizable and durable.`,
    InfoImage: iot,
  },

  {
    id: 2,
    title: `H.I.S`,
    Info: `The Healthcare Management Software is designed to streamline and enhance healthcare facility operations by providing a comprehensive platform for patient information management, appointment scheduling, billing, and overall administrative tasks. The software aims to improve patient care, optimize resource allocation, and simplify administrative processes. The Healthcare Management Software is a state-of-the-art solution designed to transform the way healthcare facilities operate, streamline administrative tasks, enhance patient care, and empower medical professionals with cutting-edge tools. This comprehensive software platform is meticulously crafted to address the unique challenges faced by healthcare providers, offering a seamless and integrated system that revolutionizes patient management, appointment scheduling, billing, and more.
    Experience a paradigm shift in healthcare management. Our Healthcare Management Software empowers you to elevate patient care, streamline operations, and optimize your healthcare facility's potential. Welcome to a future where technology and healthcare seamlessly merge.`,
    InfoImage: sql,
  },

  {
    id: 3,
    title: `Track & Trace`,
    Info: `In an era defined by global interconnectedness, supply chains have become more complex than ever before. Our Track and Trace Software Suite is your answer to conquering the challenges of traceability, accountability, and visibility in modern supply chain management. Unveil the power of real-time tracking, comprehensive reporting, and robust analytics to enhance efficiency, ensure compliance, and drive unparalleled customer satisfaction.
    
    The Track and Trace Software Suite is a comprehensive solution tailored for industries that demand precise control and transparency across the supply chain. Designed to empower manufacturers, distributors, and retailers alike, our software suite offers a seamless end-to-end track-and-trace experience.
    
    The Track and Trace Software Suite redefines the way you manage your supply chain. With unmatched visibility, robust reporting, and a commitment to innovation, our software empowers your organization to master the intricacies of modern supply chain management. Elevate your efficiency, ensure compliance, and foster customer trust with the ultimate tool in your supply chain arsenal. Welcome to a new era of traceability and accountability.`,
    InfoImage: world,
  },
  {
    id: 4,
    title: `Json-Base`,
    Info: `A database software completely built as JSON files in backend. A powerful, portable and simple database works on top of JSON files. It is like a database software, currently having basic CRUD operation features. You can use this as a backend for your ReST APIs as well.
    The software is completely free and opensource. We are coming up with new features and providing more updates. The another beautiful advantage with JSON-base is since it is a NPM module, this fits well in your nodeJs applications eco system. if you want to develop quick prototypes/poc or need of a database with minimal requirements then, JSONBASe is an must option that you can consider. However there is a limitation if you go beyond a million records per table.`,
    InfoImage: json,
  },
];

export default ProductsData;
