import React, { useEffect } from "react";
import "../TermsOfUsepage/TermsOfUse.css";
const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="TermsOfUseHeading">
        <p className="TermsOfUseHeading2">Terms Of Use&nbsp;</p>
      </div>
      <div className="TermsOfUseContainer paragraphCenter">
        <div className="aboutTextBox">
          <h3>Introduction</h3>
          <br/>
          <p>
            This website is operated by Zyxan Technologies. The terms “we”, “us”, and “our” refer to Zyxan Technologies. 
            The use of our website is subject to the following terms and conditions of use, as amended from time to time (the “Terms”).
            The Terms are to be read together by you with any terms, conditions or disclaimers provided in the pages of our website. Please review the Terms carefully. 
            The Terms apply to all users of our website, including without limitation, users who are browsers, customers, merchants, vendors and/or contributors of content. 
            If you access and use this website, you accept and agree to be bound by and comply with the Terms and our Privacy Policy. 
            If you do not agree to the Terms or our Privacy Policy, you are not authorized to access our website, use any of our website’s services or place an order on our website.
          </p>
          <br/>
          <h3>Use of our Website</h3>
          <br/>
          <p>
          You agree to use our website for legitimate purposes and not for any illegal or unauthorized purpose, including without limitation, in violation of any intellectual property or privacy law. 
          By agreeing to the Terms, you represent and warrant that you are at least the age of majority in your state or province of residence and are legally capable of entering into a binding contract.
          <br/>
          You agree to not use our website to conduct any activity that would constitute a civil or criminal offence or violate any law. You agree not to attempt to interfere with our website’s network or security features or to gain unauthorized access to our systems.
          You agree to provide us with accurate personal information, such as your email address, mailing address and other contact details in order to complete your order or contact you as needed. 
          <br/>
          You agree to promptly update your account and information. You authorize us to collect and use this information to contact you in accordance with our Privacy Policy.
          </p>
          <br/>
          <h3>General Conditions </h3>
          <br/>
          <p>
          We reserve the right to refuse service to anyone, at any time, for any reason. We reserve the right to make any modifications to the website, including terminating, changing, suspending or discontinuing any aspect of the website at any time, without notice. 
          We may impose additional rules or limits on the use of our website. You agree to review the Terms regularly and your continued access or use of our website will mean that you agree to any changes.
          <br/>
          You agree that we will not be liable to you or any third party for any modification, suspension or discontinuance of our website or for any service, content, feature or product offered through our website.
          </p>
          <br/>
          <h3>Links to Third-Party Websites</h3>
          <br/>
          <p>
            We don't have any links to other third party websites.
          </p>
          <br/>
          <h3>Disclaimer and Limitation of Liability</h3>
          <br/>
          <p>
          You assume all responsibility and risk with respect to your use of our website, which is provided “as is” without warranties, representations or conditions of any kind, either express or implied, with regard to information accessed from or via our website, including without limitation, all content and materials, and functions and services provided on our website, all of which are provided without warranty of any kind, including but not limited to warranties concerning the availability, accuracy, completeness or usefulness of content or information, uninterrupted access, and any warranties of title, non-infringement, merchantability or fitness for a particular purpose. 
          <br/>
          We do not warrant that our website or its functioning or the content and material of the services made available thereby will be timely, secure, uninterrupted or error-free, that defects will be corrected, or that our websites or the servers that make our website available are free of viruses or other harmful components.
          The use of our website is at your sole risk and you assume full responsibility for any costs associated with your use of our website. We will not be liable for any damages of any kind related to the use of our website.
          <br/>
          In no event will we, or our affiliates, our or their respective content or service providers, or any of our or their respective directors, officers, agents, contractors, suppliers or employees be liable to you for any direct, indirect, special, incidental, consequential, exemplary or punitive damages, losses or causes of action, or lost revenue, lost profits, lost business or sales, or any other type of damage, whether based in contract or tort (including negligence), strict liability or otherwise, arising from your use of, or the inability to use, or the performance of, our website or the content or material or functionality through our website, even if we are advised of the possibility of such damages.
          Certain jurisdictions do not allow limitation of liability or the exclusion or limitation of certain damages. In such jurisdictions, some or all of the above disclaimers, exclusions, or limitations, may not apply to you and our liability will be limited to the maximum extent permitted by law.
          </p>
          <br/>
          <h3>Entire Agreement</h3>
          <br/>
          <p>The Terms and any documents expressly referred to in them represent the entire agreement between you and us in relation to the subject matter of the Terms and supersede any prior agreement, understanding or arrangement between you and us, whether oral or in writing. 
            <br/>
            Both you and we acknowledge that, in entering into these Terms, neither you nor we have relied on any representation, undertaking or promise given by the other or implied from anything said or written between you and us prior to such Terms, except as expressly stated in the Terms.
          </p>
          <br/>
          <h3>Governing Law</h3>
          <br/>
          <p>
          Any disputes arising out of or relating to the Terms, the Privacy Policy, use of our website, or our services offered on our website will be resolved in accordance with the laws of the Province of Zyxan Technologies without regard to its conflict of law rules. 
          Any disputes, actions or proceedings relating to the Terms or your access to or use of our website must be brought before the courts of the Province of Zyxan Technologies in the City of Hyderabad,
          <br/>
          Zyxan Technologies and you irrevocably consent to the exclusive jurisdiction and venue of such courts.
          </p>
          <br/>
          <h3>Questions or Concerns</h3>
          <br/>
          <p>
          Please send all questions, comments and feedback to us at <b>info@zyxan.tech</b>.
          </p>
        </div>

      </div>
    </>
  );
};

export default TermsOfUse;
