import React, { useEffect } from "react";
import "../Careerpage/Carrerpage.css";
// import careerImage from "../../common/Assets/CareerpageImages/human-resources-people-networking-concept.jpg";
const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bgColor">
        <div className="careerHeading">
          <p className="ourcareerHeading">Careers&nbsp;</p>
        </div>
        <div>
          <h1 className="openingsHeading">CURRENT OPENINGS</h1>
        </div>
        <div className="roleBox">
          <div className="jobRoleBox">
            <h1 className="jobroleTitle">Front End Developer</h1>
            <button className="jobroleApplybtn">Apply Now</button>
          </div>
          <div className="jobRoleBox">
            <h1 className="jobroleTitle">Back End Developer</h1>
            <button className="jobroleApplybtn">Apply Now</button>
          </div>
          <div className="jobRoleBox">
            <h1 className="jobroleTitle">Mobile App Developer</h1>
            <button className="jobroleApplybtn">Apply Now</button>
          </div>
          <div className="jobRoleBox">
            <h1 className="jobroleTitle">Full Stack Developer</h1>
            <button className="jobroleApplybtn">Apply Now</button>
          </div>
          <div className="showBg">
            <div className="bgImage"></div>
          </div>
          <div className="resumeSubmitBox">
            <h1 className="resumeSubmit">Submit Your Resume</h1>
          </div>
          <div className="fileUpload">
            <input className="resumeInput" type="file" name="" id="" />
            &emsp; &emsp;&emsp;
            <button className="fileSubmitBtn">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
