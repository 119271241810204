import React, { useState, useEffect } from "react";
import "./Contactpage.css";
import contactImg from "../../common/Assets/ContactpageImages/email_with_bell_notification_chat_message_alert_new_event_web_icon_3d_illustration 1.png";
import Footer from "../../common/Footer/Footer";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [records, setRecords] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    subject: "",
    message: "",
  });

  let handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const htmlTemplate = `
      
      <p><strong>Name:</strong> ${formData.name}</p>
      <p><strong>Email:</strong> ${formData.email}</p>
      <p><strong>Contact:</strong> ${formData.contact}</p>
      <p><strong>Subject:</strong> ${formData.subject}</p>
      <p><strong>Message:</strong> ${formData.message}</p>

    `;
      sendEmail(htmlTemplate);
    }
    setRecords([...records, formData]);
    setFormData({
      name: "",
      email: "",
      contact: "",
      subject: "",
      message: "",
    });
  };

  const validateForm = () => {
    const errors = {};

    if (formData.name.trim() === "") {
      errors.name = "Name is Required";
    }

    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is Invalid";
    }

    if (formData.contact.trim() === "") {
      errors.contact = "Contact is required";
    } else if (!/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/) {
      errors.contact = "Contact number is Invalid ";
    }

    if (formData.subject.trim() === "") {
      errors.subject = "Subject is Required";
    }

    if (formData.message.trim() === "") {
      errors.message = "";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (htmlTemplate) => {
    fetch("http://localhost:3001/zyxan/Contactus", {
      method: "POST",
      body: JSON.stringify({
        toEmail: formData.email,
        subject: formData.subject,
        body: htmlTemplate,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    alert("Email sent successfully!");
  };

  return (
    <>
      <div className="ContactHeading">
        <p className="ContactUsHeading">Contact Us&nbsp;</p>
      </div>
      <div className="contactContainer">
      <div className="ContactUsContainer">
        <div className="ContactUsImage">
          <img className="contactImg" src={contactImg} alt="" />
        </div>
        <div className="ContactUsForm">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="inputForm">
              <input
                type="text"
                id="name"
                name="name"
                className="inputform"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                placeholder="Enter your name"
              />
            </div>
            {errors.name && <span className="errorMessage">{errors.name}</span>}
            <div className="inputForm">
      
              <input
                type="email"
                id="email"
                name="email"
                className="inputform"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                placeholder="Enter your Email ID"
              />
            </div>
            {errors.email && (
              <span className="errorMessage">{errors.email}</span>
            )}
            <div className="inputForm">
           
              <input
                type="text"
                id="contact"
                name="contact"
                className="inputform"
                value={formData.contact}
                onChange={(e) =>
                  setFormData({ ...formData, contact: e.target.value })
                }
                placeholder="Enter your Mobile Number"
              />
            </div>
            {errors.contact && (
              <span className="errorMessage">{errors.contact}</span>
            )}
            <div className="inputForm">
       
              <input
                type="text"
                id="subject"
                name="subject"
                className="inputform"
                value={formData.subject}
                onChange={(e) =>
                  setFormData({ ...formData, subject: e.target.value })
                }
                placeholder="Please specify the reason"
              />
            </div>
            {errors.subject && (
              <span className="errorMessage">{errors.subject}</span>
            )}
            <div class="container">
              <textarea
                id="message"
                name="message"
                class="fixed-size-textarea"
                rows="4"
                cols="50"
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
                placeholder="Message"
              ></textarea>
            </div>
            {errors.message && (
              <span className="errorMessage">{errors.message}</span>
            )}

            <button type="submit" className="sendButton">
              Submit
            </button>
          </form>
        </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default Contact;
