import React, { useState } from "react";
import "../../components/Productspage/Productspage.css";
import ProductsData from "../../components/Productspage/Productsinfodata";

const ProductCard = () => {
  const [showMore, setShowMore] = useState(false);
  const [select, setSelect] = useState(false);

  const handleShowMore = (item) => {
    if (select?.id === item.id) {
      setShowMore(false);
      setSelect(null);
    } else {
      setShowMore(true);
      setSelect(item);
    }
  };
  return (
    <>
      {ProductsData.map((ProductData, index) => (
        <div className="productsContainer" key={index}>
          <div className="productInfo">
            <h1 className="productsHeadline">{ProductData.title}</h1>
            <h6 className="productsInfoText">
              {showMore && ProductData.id === select.id
                ? ProductData.Info
                : `${ProductData.Info.substring(0, 220)}`}
              <button
                className="btn"
                onMouseEnter={() => {
                  handleShowMore(ProductData);
                }}
              >
                {showMore && ProductData.id === select.id
                  ? "Show less"
                  : "Show more"}
              </button>
            </h6>
          </div>
          <div className="productslogoBox">
            <img className="productsLogo" src={ProductData.InfoImage} alt="" />
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductCard;
