import React from "react";
import facebook from "./logos/image 6.png";
import twitter from "./logos/image 3.png";
import insta from "./logos/image 4.png";
import pintrest from "./logos/image 5.png";
import "./Footer.css";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <div className="addressFooter">
        <div className="indianAddress">
          <h1 className="nameHeading">INDIA ADDRESS</h1>
          <h4 className="address">4th Floor,Alluri Trade Center,</h4>
          <h4 className="address"> Usha Mullapudi Cardio Hospital Rd,</h4>
          <h4 className="address">Bhagya Nagar Colony,Venkat Nagar Colony,</h4>
          <h4 className="address">Hyderabad,Telangana - 500072</h4>
          <h4 className="address"> Email: info@zyxan.tech</h4>
          <h4 className="address">Call Us: +91 8985220221</h4>
        </div>

        <div className="usAddress">
          <h1 className="nameHeading">US ADDRESS</h1>
          <h4 className="address">4515 AL RAZI STREET IRVING, TX,75062</h4>
          <h4 className="address"> Email: habib.sheikh@zyxantek.com</h4>
          <h4 className="address">Call Us: 414-323-3434</h4>
        </div>

        <div className="usAddress">
          <h1 className="nameHeading">Support</h1>
          <Link className="address" to="/termsofuse">Terms of Use </Link><br/>
          <Link className="address" to="/privacypolicy">Privacy Policy</Link><br/>
        </div>

        <div className="socialMedia">
          <img className="socialMediaIcon" src={facebook} alt="" />
          &emsp;
          <img className="socialMediaIcon" src={twitter} alt="" />
          &emsp;
          <img className="socialMediaIcon" src={insta} alt="" />
          &emsp;
          <img className="socialMediaIcon" src={pintrest} alt="" />
        </div>
      </div>
      
    </>
  );
};

export default Footer;
