import metamech from "../../common/Assets/ClientsPageImages/metamechx-logo-gw.svg";
import tartan from "../../common/Assets/ClientsPageImages/tartan.png";
import ssr from "../../common/Assets/ClientsPageImages/ssr.png";
import elite from "../../common/Assets/ClientsPageImages/healthcare.jpg";

const ClientProjectData = [
  {
    id: 1,
    img: metamech,
    name: `MetaMechX`,
    description: `I've used the Zyxan services and had a great business using it. As the Design and Development was done in stylish and functional manner.`,
  },
  {
    id: 2,
    img: tartan,
    name: `Tartan Supply`,
    description: `I've used the Zyxan services and had a great business using it. As the Design and Development was done in stylish and functional manner.`,
  },
  {
    id: 3,
    img: ssr,
    name: `SSR Concrete`,
    description: `I've used the Zyxan services and had a great business using it. As the Design and Development was done in stylish and functional manner.`,
  },
  {
    id: 4,
    img: elite,
    name: `Elite`,
    description: `I've used the Zyxan services and had a great business using it. As the Design and Development was done in stylish and functional manner.`,
  },
];
export default ClientProjectData;
