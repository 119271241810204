import React, { useEffect } from "react";
import ServiceCard from "../../common/ServicesCardsDataComponent/ServiceCard";
import "./Servicespage.css";
import ServicesInfo from "./Servicesinfodata";

const Services = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="ServicesHeading">
        <p className="OurServicesHeading">Our Services&nbsp;</p>
      </div>
      <div className="serviceContainer">
        {ServicesInfo.map((i, index) => (
          <ServiceCard
            key={index}
            logo={i.logo}
            title={i.title}
            info={i.info}
          />
        ))}
      </div>
    </>
  );
};

export default Services;
