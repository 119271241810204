import React,{useEffect} from "react";
import "./Productspage.css";
import ProductCard from "../../common/ProductsCardDataComponent/ProductCard";



const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="productsHeading">
        <p className="ourProductsHeading">Products&nbsp;</p>
      </div>
        <ProductCard />
    </>
  );
};

export default Products;
