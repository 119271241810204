import { useRoutes } from "react-router-dom";

// layouts
import Layout from "../common/Layout/Layout";
import About from "../components/Aboutpage/Aboutpage";
import Clients from "../components/Clientspage/Clinetspage";
import Contact from "../components/Contactpage/Contactpage";
import Home from "../components/Homepage/Homepage";
import Products from "../components/Productspage/Productpage";
import Services from "../components/Servicepage/Servicepage";
import Career from "../components/Careerpage/Careerpage";
import PrivacyPolicy from "../components/PrivacyPolicypage/PrivacyPolicy";
import TermsOfUse from "../components/TermsOfUsepage/TermsOfUse";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: '', element: <Home /> },
        { path: "home", element: <Home /> },
        { path: "about", element: <About /> },
        { path: "services", element: <Services /> },
        { path: "products", element: <Products /> },
        { path: "clients", element: <Clients /> },
        { path: "career", element: <Career /> },
        { path: "contact", element: <Contact /> },
        { path: "privacypolicy", element: <PrivacyPolicy /> },
        { path: "termsofuse", element: <TermsOfUse /> },
      ],
    },
  ]);
}
