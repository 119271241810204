import "./Clientspage.css";
import React,{useEffect} from "react";
// import ClientAboutcard from "../../common/ClientCardDataComponent/ClientAboutCard";
import ClientProject from "../../common/ClientCardDataComponent/ClientsCard";

const Clients = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="ourClientContainer">
        <div className="clientProjectHeading">
          <p className="ourClientProjectHeading">Clients&nbsp;</p>
        </div>
        <ClientProject />
      </div>
      {/* <div className="clientHeading">
        <p className="ourClientsHeading">Clients About Us&nbsp;</p>
      </div>
      <div className="ourClientAboutContainer">
        <ClientAboutcard />
      </div> */}
    </>
  );
};
export default Clients;
