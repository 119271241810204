import "../Homepage/Homepage.css";
import About from "../Aboutpage/Aboutpage";
import Services from "../Servicepage/Servicepage";
import Products from "../Productspage/Productpage";
import Contact from "../Contactpage/Contactpage";
import globe from "../../common/Assets/HomePageImages/Group 1.png";
import Typewriter from "typewriter-effect";
import Clients from "../Clientspage/Clinetspage";
import Career from "../Careerpage/Careerpage";


const Home = () => {
  return (
    <>
      <div className="backgroundImage">
        <div className="homeText ">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(2000)
                .typeString("We Love To Build Mobile Apps")
                .pauseFor(1000)
                .deleteAll()
                .typeString("We Love To Build Web Design")
                .pauseFor(1000)
                .deleteAll()
                .typeString("We Love To Build Web Applications")
                .pauseFor(1000)
                .deleteAll()
                .typeString(
                  "Your idea may sound crazy for others, but we have a team who understands it!"
                )
                .start();
            }}
          />
        </div>
        <div className="globe">
          <img className="globeImg" src={globe} alt="" />
        </div>
      </div>
      <About />
      <Services />
      <Products />
      <Clients />
      <Career />
      <Contact />
   
    </>
  );
};
export default Home;
