import { combineReducers, configureStore } from '@reduxjs/toolkit';

import CounterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';

// Combining all reducers and initializing the store

const rootReducer = combineReducers({
  counter: CounterReducer,
  auth: authReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
