import React from 'react'
import "./Footer.css";

function Copyright() {
  return (
    
      <div className="footer">
        <h1 className="zyxanRights">&#169;2023 Zyxan Technologies</h1>
      </div>
    
  )
}

export default Copyright

