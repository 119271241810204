import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AuthService } from '../../services/auth.service';

const intialState = {
  user: null,
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isAuthenticated: false,
};

/**
 * Write your Async reducers here and add builders for them to the extraReducers object
 */
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  const authService = new AuthService();
  try {
    /**
     * TODO - Use this to do API call
     */
    // const response = await authService.login(user);
    const response = {
      status: 'success',
      data: {
        firstName: 'John',
        lastName: 'Doe',
        emailAddress: 'john.doe@email.com',
        phone: '1234567890',
        status: 'active',
      },
    };
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const logout = createAsyncThunk(
  'auth/logout',
  async (user, thunkAPI) => {
    const authService = new AuthService();
    try {
      console.log('logout');
      const response = await authService.logout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.success = action.payload;
        if (action.payload && action.payload.data) {
          state.user = action.payload.data;
          state.isAuthenticated = true;
        }
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        if (action.payload) {
          state.error = action.payload;
        }
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.jwt = null;
        state.isAuthenticated = false;
      });
  },
});

export const { reset } = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
