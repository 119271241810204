import React, { useEffect } from "react";
import "../Aboutpage/Aboutpage.css";
import teamlogo from "../../common/Assets/AboutPageImages/Team-removebg-preview.png";
import vuejs from "../../common/Assets/AboutPageImages/brands.png";
import eclipse from "../../common/Assets/AboutPageImages/Eclipse_(software)-Logo.wine.png";
import figma from "../../common/Assets/AboutPageImages/figma.png";
import html from "../../common/Assets/AboutPageImages/html-5 1.png";
import intelli from "../../common/Assets/AboutPageImages/IntelliJ-IDEA.png";
import photoshop from "../../common/Assets/AboutPageImages/photoshop-express 1.png";
import react from "../../common/Assets/AboutPageImages/physics (1).png";
import vscode from "../../common/Assets/AboutPageImages/visual-studio.png";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="AboutHeading">
        <p className="AboutUsHeading">About Us&nbsp;</p>
      </div>
      <div className="aboutUsContainer">
        <div className="aboutTextBox">
          <p className="AboutUsText">
            Welcome to Zyxan Tech, a software company dedicated to providing innovative solutions to businesses of all sizes. Our team of experienced developers, designers, project managers and product managers are passionate about technology and are committed to creating software solutions that make a difference in people's lives.
          </p>
          <p>
            At Zyxan Tech, we believe that technology should simplify your work, not complicate it. That's why we focus on creating software that is intuitive, user-friendly, and efficient. Whether you're looking for a custom web application, a mobile app, or an e-commerce platform, we've got you covered. We understand that technology is constantly evolving, which is why we strive to stay ahead of the curve. We're always exploring new technologies and trends to ensure that our products are cutting-edge and meet the needs of our clients.
          </p>
          <p>
            We're committed to creating products that are not only functional but also beautiful. Our team of designers is passionate about creating products that are visually stunning and aesthetically pleasing, while still maintaining a focus on usability and functionality.
          </p>
          <p>
            Our team is dedicated to providing exceptional customer service and support. We believe that strong relationships with our clients are the key to our success, which is why we take a personalized approach to every project we undertake. Our goal is to ensure that our clients are happy with the products we deliver, and we work tirelessly to exceed their expectations.
          </p>
          <p>
            Our mission is to help businesses achieve their goals by leveraging the latest technologies and trends. We pride ourselves on being at the forefront of the industry, and we're always exploring new ways to improve our products and services.
          </p>
          <p>
            We understand that every business is different, which is why we take a personalized approach to every project we undertake. Our team will work closely with you to understand your unique requirements, and we'll provide you with a solution that is tailored to your needs.
            At Zyxan Technologies, we're committed to delivering high-quality products and services at competitive prices. We believe that technology should be accessible to everyone, regardless of the size of their business or their budget.
          </p>
          <p>
            At Zyxan Tech, we're always looking for ways to make a positive impact in our community and the world. We believe that technology can be a powerful tool for good, and we're committed to using our skills and expertise to create products that make a difference. Thank you for considering Zyxan. Tech for your software needs. We're excited to work with you and create products that meet your unique needs and exceed your expectations.
          </p>
        </div>

        <div className="AboutUsAnimation">
          <div className="main">
            <div className="bigCircle">
              <div className="iconBlock">
                <img src={vuejs} alt="web design icon" />
              </div>
              <div className="iconBlock">
                <img src={eclipse} alt="game design icon" />
              </div>
              <div className="iconBlock">
                <img src={react} alt="game dev icon" />
              </div>
              <div className="iconBlock">
                <img src={photoshop} alt="ui-ux icon" />
              </div>
            </div>
            <div className="circle">
              <div className="iconBlock">
                <img src={figma} alt="app icon" />
              </div>
              <div className="iconBlock">
                <img src={html} alt="blockchain icon" />
              </div>
              <div className="iconBlock">
                <img src={vscode} alt="ar-vr icon" />
              </div>
              <div className="iconBlock">
                <img src={intelli} alt="artificial intelligence icon" />
              </div>
            </div>
            <div className="centerLogo">
              <img src={teamlogo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
