import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Clients from "../../components/Clientspage/ClientsProjectInfo";

const ClientProject = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 1500,
    centerMode: true,
    className: "slider-item",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {Clients.map((Client, index) => (
        <div key={Client.id} className="client">
          <img className="clientproImg" src={Client.img} alt={Client.title} />
          <h1 className="clentproTitle">{Client.name}</h1>
          <h2 className="clinetprojectDescription">{Client.description}</h2>
        </div>
      ))}
    </Slider>
  );
};

export default ClientProject;
